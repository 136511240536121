.modal-90w {
  max-width: 40%;
}
.modal-body {
  font-size: 0.8rem;
  text-align: justify;
}
.custom-modal .modal-content {
  padding: 1rem;
}
.cookie-button {
  background-color: #ff9935;
  border: 2px solid #ff9935;
  color: white;
  transition: background-color 0.3s, transform 0.3s;
}
.cookie-button:hover {
  background-color: #e68a2c;
  cursor: pointer;
  animation: bounce 0.8s;
}
.cookie-button {
  background-color: #ff9935;
  border: 2px solid #ff9935;
  color: white;
  padding: 10px 30px;
  margin-inline: 10px;
  border-radius: 50px;
  transition: background-color 0.3s, transform 0.3s;
  width: 180px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  box-sizing: border-box;
}
.cookie-button:hover {
  background-color: #ff9935;
  border: 2px solid #ff9935;
  cursor: pointer;
  animation: bounce 0.8s;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@media (max-width: 850px) {
  .modal-90w {
    max-width: 90% !important;
  }
}

@media (max-width: 576px) {
  .custom-modal .modal-dialog {
    margin: 0.5rem auto;
  }

  .modal-90w {
    max-width: 90% !important;
  }

  .custom-modal .modal-header img {
    max-width: 120px;
  }

  .custom-modal h5 {
    font-size: 0.75rem;
  }

  .custom-modal .modal-body p {
    font-size: 0.6rem;
    padding: 0 10px;
  }

  .cookie-button {
    width: 100px;
    height: 30px;
    padding: 8px 16px;
    font-size: 0.6rem;
    border-radius: 20px;
  }
}
