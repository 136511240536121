/* ProjectCard.css */
.project-card {
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: rgb(255, 255, 255);
  padding-top: 100%;
}
.project-card-active {
  transition: all 0.3s ease-in-out;
}

.project-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}
.project-image-active {
  transition: all 0.3s ease-in-out;
}

.project-info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.project-info-active {
  transition: all 0.3s ease-in-out;
}

.project-title {
  color: black;
  text-align: center;
  margin: 0;
  padding: 10px;
}

.project-card-active:hover .project-info {
  opacity: 0.9;
}

@media (max-width: 768px) {
  .project-card {
    margin-bottom: 20px;
  }
}
