@font-face {
  font-family: "apercuregular";
  src: url("./fonts/apercu_regular_pro.woff2") format("woff2"),
    url("./fonts/apercu_regular_pro.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "apercumedium";
  src: url("./fonts/apercu_medium_pro.woff2") format("woff2"),
    url("./fonts/apercu_medium_pro.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "apercubold";
  src: url("./fonts/apercu_bold_pro.woff2") format("woff2"),
    url("./fonts/apercu_bold_pro.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap");
/* FONT TYPES */
.arimo {
  font-family: "Arimo", sans-serif !important;
}
.apercuregular {
  font-family: "apercuregular" !important;
}
.apercubold {
  font-family: "apercubold" !important;
}
.apercumedium {
  font-family: "apercumedium" !important;
}
.cursorpointer {
  cursor: pointer;
}
/* FONT SIZES */
.f40 {
  font-size: 40px;
}
.f30 {
  font-size: 30px;
}
.f20 {
  font-size: 20px;
}
.f15 {
  font-size: 15px;
}
body {
  font-family: "arimo" !important;
  background-color: white !important;
  color: black !important;
}
a {
  text-decoration: none !important;
  color: inherit !important;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track-piece {
  background-color: #262626;
}
::-webkit-scrollbar-thumb:vertical {
  height: 30px;
  background-color: black;
}
.logo {
  width: 240px;
}
.footer {
  background-color: #ffffff;
  color: #505050;
  width: 100%;
}
button {
  background-color: #535353;
  color: white;
  /* border-radius: 20px !important; */
  border: 0;
  padding: 5px 20px 5px 20px;
}
button:hover {
  background-color: white;
  color: #505050;
  /* border-radius: 20px !important; */
  border: 0;
  padding: 5px 20px 5px 20px;
}
.btngrey {
  background-color: #535353;
  color: white;
}
.btnbrown {
  background-color: #6a5045;
  color: white;
}
.bglightgrey {
  background-color: #cfc4bf;
}
.bglightgrey2 {
  background-color: #e5e1df9c;
}
.bglightgrey3 {
  background-color: #d9d9d9;
}
.bglightbrown {
  background-color: #efebe3;
}
.bglightbrown2 {
  background-color: #cfc5bf;
}
.colorbrown {
  color: #6a5045;
}
.colorgrey {
  color: #535353;
}
.submenu {
  height: 24px;
}
.nav-item {
  padding: 2px 10px 2px 10px;
}
.nav-item:hover {
  background-color: #a2a2a2;
  color: white;
  border-radius: 17px;
  padding: 2px 10px 2px 10px;
  cursor: pointer;
}
.nav-link {
  color: #505050 !important;
  padding: inherit !important;
}
.nav-link:hover {
  background-color: #a2a2a2;
  color: white !important;
}
.submenu .nav-link:hover {
  background-color: inherit;
  color: black !important;
}
.submenu ul {
  left: 50%;
}
hr {
  margin-bottom: 3px !important;
}
.icoarrow {
  width: 13px;
}
/*scroll button*/
.top-to-btm {
  position: relative;
}
.icon-position {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
  padding-left: 50px !important;
  background-color: black;
}
.icon-position img {
  margin-left: 20px;
}
.icon-position:hover {
  background-color: #535353;
  color: white;
}
/** HOME **/
.carousel-control-prev {
  display: none !important;
}
.carousel-control-next-icon {
  background-color: #6a5045;
  border-radius: 20px;
  background-size: 25px !important;
}
.btnoutline {
  border: 1px solid #535353;
  background-color: inherit;
  color: #535353;
  font-size: 14px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.btnoutline:hover {
  border: 1px solid #535353;
}
/* SEARCHBAR */
.search-icon-container {
  display: flex;
  align-items: center;
  padding: inherit;
  width: 50px;
  transition: width 0.5s ease-in-out;
  color: white;
}
.search-icon-container:hover {
  width: 180px;
}
.search-icon-container input {
  flex-grow: 1;
  border: none;
  border-radius: 5px;
  outline: none;
  width: 0;
  transition: width 0.5s ease-in-out;
  opacity: 0;
}
.search-icon-container:hover input {
  width: 100%;
  opacity: 1;
  margin-left: 10px;
}
/* MULTILANGUAGE MENU */
.lang {
  align-items: center;
}
.btnlang {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
}
.languages {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0.75rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  right: 0;
  top: 100%;
  z-index: 1000;
  width: max-content;
}
.languages div {
  padding: 0.5rem 1rem;
  border-radius: 0.75rem;
  cursor: pointer;
  white-space: nowrap;
}
.languages div:hover {
  background-color: #f8f8f8;
}
/* COOKIE BANNER */
.cookie-consent-banner::part(primary-button) {
  text-transform: uppercase;
}
.cookie-consent-banner::part(secondary-button) {
  text-transform: uppercase;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.cookie-consent-banner::part(checkbox) {
  accent-color: rgb(24, 251, 107);
}
.cookie-consent-banner::part(description),
.cookie-consent-banner::part(headline) {
  font-family: "Arial", sans-serif;
}
/* PROJECT */
.fadeIn {
  animation: fadeInAnimation ease 4s;
}
.fadeInNavbar {
  animation: fadeInAnimation ease 0.5s;
}
.fadeOutNavbar {
  animation: fadeOutAnimation ease 0.5s;
}
.gallery-item {
  opacity: 0;
  margin-bottom: 15px;
}
.fade-in {
  animation: fadeInAnimation ease 4s;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
