@media (max-width: 767px) {
  .top {
    z-index: 2000;
    padding-right: 0;
    margin-right: 0;
  }
  .mobile-menu-overlay {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* background-color: rgb(223, 224, 225); */
    z-index: 1000;
  }

  .show-mobile-menu-overlay {
    display: block;
  }

  .menu-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .menu-content a {
    display: block;
    margin: 10px 0;
  }

  .creAmos-logo {
    width: 150px;
  }
}

@media (min-width: 768px) {
  .navbar-collapse {
    display: flex !important;
  }
  .creAmos-logo {
    width: 200px;
  }
}

.text-orange {
  color: rgb(255, 119, 0);
}
.hover-orange:hover {
  color: rgb(255, 125, 4);
}
