.footer {
  padding: 20px 0;
}

.footer a {
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.social-icon {
  width: 24px;
  height: 24px;
  transition: transform 0.3s;
}

.social-icon:hover {
  transform: scale(1.2);
}

@media (max-width: 768px) {
  .footer .row {
    text-align: center;
  }

  .footer .col-md-3 {
    margin-bottom: 15px;
  }
}

@media (max-width: 576px) {
  .footer .social-icon {
    width: 20px;
    height: 20px;
  }
}
